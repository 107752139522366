// // 加载echarts，注意引入文件的路径
import * as echarts from 'echarts/lib/echarts';

//引入主题
import customedZT from './customed.json'
echarts.registerTheme('customed', customedZT)

// 引入图表类型，
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/pictorialBar';
import 'echarts/lib/chart/radar';
import "echarts/lib/chart/pie";




// 标题，提示信息等
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/dataset';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/dataZoom';


import { CanvasRenderer } from 'echarts/renderers';
import { FunnelChart, SunburstChart } from 'echarts/charts';

import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  LegendComponent,
  FunnelChart,
  CanvasRenderer,
  SunburstChart
]);


export default echarts
